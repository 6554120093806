<!-- 填写勋章 -->
<template>
  <div>
    <van-nav-bar
      title="勋章报告"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="fillIn">
      <div class="ctxtitle">
        本阶段练习中你可能遇到了哪些阻碍<span class="ctxtitle2"></span>
      </div>
      <div class="ctxlist">
        <div
          v-for="(item, index) in options1"
          :key="index"
          :class="[
            'listItem',
            selectedOptions1.includes(index) ? 'active' : '',
          ]"
          @click="toggleOption(selectedOptions1, index)"
        >
          {{ item }}
        </div>
      </div>

      <div class="ctxtitle1">
        有哪些方法能帮你克服它们，坚持完成了练习？<span
          class="ctxtitle2"
        ></span>
      </div>
      <div class="ctxlist">
        <div
          v-for="(item, index) in options2"
          :key="index"
          :class="[
            'listItem',
            selectedOptions2.includes(index) ? 'active' : '',
          ]"
          @click="toggleOption(selectedOptions2, index)"
        >
          {{ item }}
        </div>
      </div>

      <div class="ctxtitle1" v-if="ok == 0">
        想要坚持后续练习，获得更好的练习体验，现在你可以开始做到的“一小步”是什么？<span
          class="ctxtitle2"
        ></span>
      </div>
      <div class="ctxtitle1" v-if="ok == 1">
        在后续的练习中，能够让自己有更好体验的“一小步”调整是怎样的？<span
          class="ctxtitle2"
        ></span>
      </div>
      <img
        class="shurukuang"
        src="https://lesson.iapeap.com/images/EmotionalValue/shurukuang.png"
        alt=""
      />

      <textarea v-model="comment" name="" id="" cols="30" rows="10"></textarea>
      <div class="btntj">
        <button @click="openPopup">提交</button>
      </div>
    </div>
    <div class="over" v-if="showPopup">
      <div class="overbox">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/1jie.png"
          alt=""
          v-if="stage == 1"
        />
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/2jie.png"
          alt=""
          v-if="stage == 2"
        />
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/3jie.png"
          alt=""
          v-if="stage == 3"
        />
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/4jie.png"
          alt=""
          v-if="stage == 4"
        />
        <div>已领取报告</div>
        <div>
          可前往 <span class="overbox-blue">【练习档案】</span>——
          <span class="overbox-blue">【练习档案】</span>中查看
        </div>
        <button @click="goBack">我知道了</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getMedalList, saveMedal } from "../../api/login";
export default {
  data() {
    return {
      comment: "",
      showPopup: false, // 弹窗的显示状态
      stage: "",
      selectedOptions1: [], // 存储第一组选项的选中索引
      selectedOptions2: [], // 存储第二组选项的选中索引
      options1: ["没有时间", "拖延", "比较懒，不想做", "其他"],
      options2: [
        "每天固定一个练习时间",
        "完成练习就给自己一个小奖励",
        "找一个“监督”提醒自己的练习伙伴",
        "其他",
      ],
      ok: "0",
    };
  },
  mounted() {
    this.stage = this.$route.query.stage;
    this.ok = this.$route.query.ok;
    console.log(this.ok);

    this.getgetMedalList();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    // 保存

    openPopup() {
      let data = {
        userId: window.localStorage.getItem("userId"),
        comment: this.comment,
        option1: this.selectedOptions1,
        option2: this.selectedOptions2,
        option3: [],
        stage: this.stage,
      };
      saveMedal(data).then((res) => {});
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      document.body.style.overflow = "auto";
    },
    getgetMedalList() {
      let data = {
        userId: window.localStorage.getItem("userId"),
      };
      getMedalList(data).then((res) => {});
    },
    toggleOption(selectedArray, index) {
      const selectedIndex = selectedArray.indexOf(index);
      if (selectedIndex === -1) {
        selectedArray.push(index);
      } else {
        selectedArray.splice(selectedIndex, 1);
      }
      console.log(this.selectedOptions1, this.selectedOptions2);
    },
  },
};
</script>

<style lang="less">
.fillIn {
  padding: 10px 20px;
  .ctxtitle {
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #000025;
    line-height: 22px;
  }
  .ctxtitle2 {
    height: 14px;
    font-size: 10px;
    font-weight: 300;
    color: #000025;
    line-height: 14px;
    letter-spacing: 1px;
  }
  .ctxtitle1 {
    margin-top: 40px;
    font-size: 16px;
    font-weight: 500;
    color: #000025;
    line-height: 22px;
  }
  .ctxlist {
    .listItem {
      width: 279px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
      border-radius: 8px;
      font-size: 13px;
      font-weight: 500;
      color: #000025;
      line-height: 40px;
      letter-spacing: 1px;
      padding: 0 20px;
      box-sizing: border-box;
      margin: 0 20px;
      margin-top: 20px;
    }
    .active {
      height: 40px;
      background: rgba(211, 227, 247, 0.5);
      box-shadow: 0px 2px 8px 0px #d8e9ff;
      border-radius: 8px;
      border: 1px solid #72acf4;
      font-size: 13px;
      font-weight: 500;
      color: #629ee9;
      line-height: 40px;
      letter-spacing: 1px;
    }
  }
  textarea {
    width: 100%;
    border: #c1d1e4 solid 2px;
    border-radius: 10px;
    padding: 20px 30px;
    box-sizing: border-box;
  }
  .shurukuang {
    width: 16px;
    height: 15px;
    position: relative;
    top: 44px;
    left: 10px;
  }
  .btntj {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 60px;
    button {
      width: 200px;
      height: 36px;
      color: #fff;
      line-height: 36px;
      background: #629ee9;
      border-radius: 50px;
      border: 0;
    }
  }

  // 弹窗
  .tanchuang {
    width: calc(100% - 40px);
    height: 100vh;
    position: fixed;
    top: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 300px;
      height: 346px;
    }
  }
}
.navbar {
  background-color: #ededed;
}
.van-nav-bar .van-icon {
  color: #464646;
}
.over {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .overbox-blue {
    color: #72acf4;
  }
  .overbox {
    padding: 0 20px;
    box-sizing: border-box;
    width: 300px;
    height: 304px;
    background-color: #fff;
    box-shadow: 1px 4px 4px 2px rgba(0, 0, 0, 0.192);
    border-radius: 25px;
    text-align: center;
    color: #333;
    img {
      position: relative;
      // left: calc(50% - 75px);
      bottom: 30px;
      width: 150px;
      height: 140px;
    }
    button {
      color: #fff;
      border-radius: 25px;
      background-color: #72acf4;
      width: 224px;
      height: 36px;
      border: 0;
      margin-top: 30px;
    }
  }
}
</style>
